import React from "react";
import Section from "../../components/Section/Section";
import "./RelatedItems.scss";
import Card from "../../components/Card/Card";

const RelatedItems = ({ heading, items }) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Section className="RelatedItems">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 Heading--line">
            <h3 className="title">{heading}</h3>
          </div>
        </div>
        <div className="row">
          {items?.map((item, i) => {
            return (
              <div className="col-lg-4" key={i}>
                <Card
                  uri={item.uri}
                  title={item.title}
                  description={item.baseFields.previewDescription}
                  image={item.baseFields?.previewImage || item.featuredImage?.node}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

export default RelatedItems;
