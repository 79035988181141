import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import popup from 'utils/animations/popup';
import './PopUp.scss';


const PopUp = ({ children, className, duration = 1, delay = 0 }) => {
  const animateInOptions = { delay, snap: true }
  const [ ref ] = popup(duration,  { animateInOptions });
  const classes = classNames('animation__popup', className);

  return (
    <div ref={ref} className={classes}>
      {children}
    </div>
  );
};

PopUp.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  delay: PropTypes.number,
  duration: PropTypes.number,
};

export default PopUp;
