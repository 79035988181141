import { useCallback, useRef } from 'react';
import { TweenMax } from 'gsap';
import ease from 'utils/animations/ease';
import useGsap from './use-gsap-hook';


const popup = (duration, options = {} ) => {
  const {
    snap = false,
    animateInOptions = {}
  } = options;

  const {
    delay = 0,
    stagger = 0.25,
    ...extraOptions
  } = animateInOptions;

  const ref = useRef();
  const easeIn = (!snap) ? ease.in : ease.inOutExpo;

  const animateInit = useCallback(() => {
    TweenMax.set(ref.current.childNodes, {
      y: '110%'
    });
  }, [ref]);

  const animateIn = useCallback(() => {
    TweenMax.staggerTo(
      ref.current.childNodes,
      duration,
      {
        ease: easeIn,
        y: 0,
        delay,
        ...extraOptions
      },
      stagger
    );
  }, [ref]);

  useGsap({ ref, animateIn, animateInit });

  return [ ref, animateIn ];
};

export default popup;
