import { useCallback, useRef } from 'react';
import { TweenMax } from 'gsap';
import ease from 'utils/animations/ease';
import useGsap from './use-gsap-hook';

const scale = (value, duration, delay = 0, useIntersect = true) => {
  const ref = useRef()

  const animateInit = useCallback(() => {
    TweenMax.to(
      ref.current.childNodes,
      duration,
      {
        scale: 1,
        ease: ease.out,
        delay
      }
    );
  }, [ref]);

  const animateIn = useCallback(() => {
    TweenMax.to(
      ref.current.childNodes,
      duration,
      {
        scale: value,
        ease: ease.in,
        delay,
      }
    );
  }, [ref]);

  if (useIntersect) {
    useGsap({ ref, animateIn, animateInit });
  }

  return [ ref, animateIn, animateInit ];
};

export default scale;
