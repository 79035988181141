import React from "react";
import { Link } from "gatsby";
import FluidImage from "../FluidImage/FluidImage";
import { Zoomer } from 'components/Animations';

import "./Card.scss";


const Card = ({ title, image, uri, description }) => {

  return (
    <Link to={uri} className="CardContainer">
      <div className="Card">
        <Zoomer className="CardImage">
          <FluidImage image={image} aspectRatio={1} />
        </Zoomer>
        <div className="CardBody">
          <div className="CardTitle">
            <h5>{title}</h5>
          </div>
          <div className="CardSubTitle" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </Link>
  );
};

export default Card;
