import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import scale from 'utils/animations/scale';

import './Zoomer.scss';


const Zoomer = ({ children, className }) => {
  const [ ref, animateIn, animateInit ] = scale(1.05, 0.25, 0.05, false);
  const classes = classNames('animation__zoomer', className);

  return (
    <div ref={ref} className={classes} onMouseEnter={animateIn} onMouseLeave={animateInit}>
      {children}
    </div>
  );
};

Zoomer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  delay: PropTypes.number,
  duration: PropTypes.number,
};

export default Zoomer;
